import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "discussing-the-pandemic-with-your-teen"
    }}>{`Discussing the pandemic with your teen`}</h2>
    <p>{`We all have questions and concerns about the pandemic — and your teen is no exception. Teenagers are likely to seek answers from their peers, online, and from social media.`}</p>
    <p>{`As a parent, it’s important for you to help your teen get the right information. Information meant for adults, or developmentally-inappropriate information, can cause anxiety or confusion for some teenagers. Talking with your teen about the pandemic can help them understand and cope with what they are hearing.`}</p>
    <p>{`How do you make sure that they are getting appropriate information? How do you help them cope with their worries?`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/3-questions.svg" alt="placeholder alt" mdxType="SingleImage" />
    <p>{`This section will cover:`}</p>
    <ul>
      <li parentName="ul">{`Finding the right information`}</li>
      <li parentName="ul">{`Having the conversations`}</li>
      <li parentName="ul">{`Addressing misconceptions`}</li>
      <li parentName="ul">{`Supporting your teen to follow the local regulations & restrictions`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      